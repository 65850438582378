<div class="modal-header flex justify-content-between">
    <h4 class="modal-title">Crear Ticket</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<!--<div class="modal-body">
    <h3>Estimado discente de acuerdo con la finalización del proceso de inscripción al “IX Curso de Formación Judicial
        Inicial para Jueces y Magistrados de la República”, nos permitimos indicar que no se procesarán solicitudes que no
        versen sobre dicha etapa del proceso que actualmente se encuentra culminada.</h3>
</div>-->
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="Save()">
        <div class="section-ticket">
            <div class="text-center mb-2">
                <small>Información del peticionario</small>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6">
                <div class="mb-3">
                    <label class="form-label">Cédula</label>
                    <input type="text" class="form-control" formControlName="institution" (blur)="loadUser($event)">
                </div>
            </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Correo</label>
                        <input type="email" class="form-control" formControlName="email" [attr.disabled]="hasEmail || null">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Nombres</label>
                        <input type="text" class="form-control" formControlName="names">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Celular</label>
                        <input type="text" class="form-control" formControlName="phone" min="3000000000"
                            max="3999999999" step="1">
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-8" *ngIf="callUserOption">
                    <div class="mb-3">
                        <label class="form-label">Fecha y hora</label>
                        <input type="datetime-local" class="form-control" formControlName="datetime_call">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-ticket">
            <div class="text-center mb-2">
                <small>Información del ticket</small>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <!-- <label class="form-label">Asunto</label>
                        <input type="text" class="form-control" formControlName="message"> -->
                        <select class="form-select" formControlName="ticket_names_id">
                            <option [value]="15">PRESENTACION RECURSO REPOSICION EN CONTRA DE LAS RESOLUCIONES 485 Y 386 DE 2024</option>
                            <!--<option [value]="14">PRESENTACION DE RECURSOS DE REPOSICION RESOLUCION EJR24-298-24</option>-->
                            <!--<option [value]="13">PETICION DE EXHIBICION DE LA EVALUACION DE LA SUBFASE GENERAL</option>-->
                            <option [value]="12">CASOS EVALUACION 02 DE JUNIO DEL 2024</option>
                            <option [value]="11">CASOS EVALUACION 19 DE MAYO DEL 2024</option>
                            <option [value]="10">INSTALACION KLARWAY</option>
                            <option [value]="1">USUARIO Y CONTRASEÑA</option>
                            <option [value]="2">ACCESO A LA PLATAFORMA</option>
                            <!--<option [value]="3">INFORMACION INSCRIPCION</option>
                            <option [value]="4">CARGUE DOCUMENTACION</option>
                            <option [value]="5">CORRECCION DOCUMENTOS</option>
                            <option [value]="6">CORRECCION DATOS INSCRIPCION</option>-->
                            <option [value]="7">INFORMACION CONTENIDO DEL CURSO</option>
                            <!-- <option [value]="9">VISITA CISP</option> -->
                            <option [value]="8">OTROS</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Descripción</label>
                    <textarea class="form-control" rows="4" formControlName="description" (input)="onDescriptionInput()"></textarea>
                    <div class="text-end">
                      <small [class.text-danger]="charCount > 1000">{{ charCount }}/1000</small>
                    </div>
                    <div *ngIf="charCount > 1000" class="text-danger">
                      La descripción no puede exceder los 1000 caracteres.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <label class="mb-1">Anexar evidencia (opcional)</label>
                    <input type="file" formControlName="path_media" #fileUpload id="fileUpload" name="fileUpload">
                </div>
            </div>
        </div>
        <div class="section-ticket" *ngIf="!free">
            <div class="text-center mb-2">
                <small>Información destino</small>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Prioridad</label>
                        <select class="form-select" formControlName="ticket_priority_id">
                            <option *ngFor="let item of priority" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Grupo destino</label>
                        <select class="form-select" formControlName="group_id">
                            <option *ngFor="let item of groups" [value]="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-grid gap-2">
                    <button class="btn btn-danger" type="submit">Guardar</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" >
    <div class="row">
        <div class="col-12">
            <!-- <small>Estimado discente, daremos respuesta en un lapso de 5 días hábiles contados a partir del recibido de
                la presente solicitud.</small> -->
        </div>
         <div class="col-12 text text-end">
            <!--<button type="button" class="btn btn-outline-dark" (click)="acceptOpt()">Aceptar</button>-->
        </div>
        <!-- <div class="col-2 text text-end">
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
        </div> -->
    </div>
</div>
